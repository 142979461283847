import * as React from "react";
import {
  Box,
  Button,
  Grid,
  Slider,
  TextField,
  Paper,
  Card,
  CardContent,
  Typography
} from "@mui/material";
import VolumeUp from "@mui/icons-material/VolumeUp";
import Api from "../../../API/API";
import cookie from "../../../API/cookie";
import { useEffect } from "react";
import { blue } from "@mui/material/colors";
var g_recv_idx = 800;
var last = 0;
var bluetoothService = null;
const WRITE_UUID = "6e400002-b5a3-f393-e0a9-e50e24dcca9e";
var willBeUploadedDataArr = [];
var defaultValue;

let user_id = cookie.getCookie("userAccount")
  ? cookie.getCookie("userAccount")
  : "";
var api_token = cookie.getCookie("accessToken");

if (user_id) {
  defaultValue = {
    key: api_token,
  };
}
var id;

function ExperimentMachineListPageMiddle(props) {
  const datas = props.data;
  const machine = props.machine;
  const [valueWidth, setValueWidth] = React.useState(0);
  const [valueDuration, setValueDuration] = React.useState(20);
  const [valueAmplitude, setValueAmplitude] = React.useState(0);
  const [valueTime, setValueTime] = React.useState(0);
  const [valueLimit, setValueLimit] = React.useState(15);
  const starttime = props.starttime;
  const [Timer, setTimer] = React.useState(0);
  const [timestatus, settimeStatus] = React.useState(false);
  let endtime;
  const signal_names = ["EEG1", "EEG2", "PPG", "X", "Y", "Z"];
  const [timestatus2, settimeStatus2] = React.useState(false);
  const [starttime2, setstarttime2] = React.useState(undefined);
  const [state, setstate] = React.useState(0);
  const [stateSetting, setstateSetting] = React.useState(0);
  const [stim, setstim] = React.useState('자극 없음');
  const [mounted, setMounted] = React.useState(false);

  let endtime2;
  const [globalInterval, setglobalInterval] = React.useState(null);

  const [timeout1, settimeout1] = React.useState(null);
  const [timeout2, settimeout2] = React.useState(null);
  const [timeout3, settimeout3] = React.useState(null);
  const [timeout4, settimeout4] = React.useState(null);
  // setInterval(() => {
  //   //30초 마다 타이머 체크
  //   setTimer(Timer + 30);
  // }, 30000);
  useEffect(() => {
    document.body.style.overflow = 'hidden';

    const getData = async () => {
      const infoBody = await Api.getAPI_ExperimentSubCreate(user_id);
      if (infoBody != null) {
        id = infoBody.data.id;
        console.log(id);
      }
      // console.log(datas);
    };
    getData();
  }, []);

  React.useEffect(() => {
    if (starttime2 == undefined) {
      if (!timestatus) {
        endtime = new Date();
        if (((endtime - starttime) / 1000) > 30) {
          console.log("down2");
          settimeStatus(true);
          bluetoothService = machine;
          bluetoothService
            .getCharacteristic(WRITE_UUID)
            .then(function (characteristic) {
              var deviceChar = characteristic;
              const cmd_intense = "100|0";
              var uint8array_intense = new TextEncoder().encode(cmd_intense);
              deviceChar
                .writeValueWithoutResponse(uint8array_intense);
            });
        }
      }
    } else {
      if (!timestatus2) {
        endtime2 = new Date();
        if (((endtime2 - starttime2) / (1000 * 60)) > valueLimit) {
          settimeStatus2(true);
          console.log("down");
          bluetoothService = machine;
          bluetoothService
            .getCharacteristic(WRITE_UUID)
            .then(function (characteristic) {
              var deviceChar = characteristic;
              const cmd_intense = "910|1";
              var uint8array_intense = new TextEncoder().encode(cmd_intense);
              deviceChar
                .writeValueWithoutResponse(uint8array_intense)
                .then(function () {
                  const cmd_interval = "100|0";
                  var uint8array_interval = new TextEncoder().encode(cmd_interval);
                  deviceChar
                    .writeValueWithoutResponse(uint8array_interval);
                });
            });
        }
      }
    }
  }, [Timer, starttime2])


  { uploadData(); }

  function uploadData() {
    // console.log(datas);
    const signal_name = "B9_11_PPG_avg";
    willBeUploadedDataArr.push({
      proto_exp_id: id,
      code: "PPG",
      time: datas[0]["t"],
      v: datas[0][signal_name],
      // regdate: new Date()
    })
    if (g_recv_idx <= last) {
      const getata = async () => {
        const infoData = await Api.getAPI_PostData(willBeUploadedDataArr, defaultValue);
        console.log(infoData);
      };
      getata();
      g_recv_idx = g_recv_idx + 600;
      willBeUploadedDataArr = [];
    }
    last = datas[0]["t"];
  }

  const handleWidthSliderChange = (event, newValue) => {
    setValueWidth(newValue);
  };

  const handleDurationSliderChange = (event, newValue) => {
    setValueDuration(newValue);
  };

  const handleAmplitudeSliderChange = (event, newValue) => {
    setValueAmplitude(newValue);
  };

  const handleTimeSliderChange = (event, newValue) => {
    if (newValue == 0) {
      newValue = valueDuration;
    }
    setValueTime(newValue);
  };

  const handleLimitSliderChange = (event, newValue) => {
    setValueLimit(newValue);
  };

  function sleep(ms) {
    return new Promise((r) => setTimeout(r, ms));
  }

  const notsham = /ntx_ec_[0-7]$/;
  const sham = /ntx_ec_(8|9|1[0-5])$/;

  const handleup = () => {
    // AddStimulus(valueAmplitude, valueWidth, valueDuration, valueTime, valueLimit);
    // AddStimulus(800, 250, 50, 20, valueLimit);
    console.log(user_id);
    if (user_id === 'ryze123') {

      function stim1_first() {
        AddStimulus(800, 250, 50, 0.25, 9999);
      }

      function stim1() {
        AddStimulus(800, 250, 50, 0.25, 1);
      }

      function stim2() {
        AddStimulus(4095, 250, 50, 0.25, 1);
      }

      function stim3() {
        AddStimulus(800, 250, 25, 0.25, 1);
      }

      function stim4() {
        AddStimulus(4095, 250, 25, 0.25, 1);
      }

      function stim4_last() {
        console.log('last');
        AddStimulus(4095, 250, 25, 0.25, 9998);
      }

      function end_stim() {
        AddStimulus(0, 0, 0, 0, 0);
        setstate(2);
        setstateSetting(2);
        setstim("자극 없음")
      }

      let iterationCount = 0;
      const maxIterations = 10;

      function runIteration() {
        if (iterationCount == 0) stim1_first();
        else stim1();

        let t1 = setTimeout(stim2, 15000);
        settimeout1(t1);
        let t2 = setTimeout(stim3, 30000);
        settimeout2(t2)
        let t3 = null
        if (iterationCount == maxIterations) t3 = setTimeout(stim4_last, 45000);
        else t3 = setTimeout(stim4, 45000);
        settimeout3(t3)
        iterationCount++;

        if (iterationCount > 0) {
          if (iterationCount == maxIterations) {
            clearInterval(intervalID); // 지정된 횟수만큼 반복 후 interval 중지
            setTimeout(end_stim, 60000);
          }
        }
      }

      runIteration();

      const intervalID = setInterval(runIteration, 60000);
      setglobalInterval(intervalID);

    }
    // else if (user_id == 'ntx_vip_new') {
    //   AddStimulus(2300, 250, 50, 5, 15);

    //   function stim_2() {
    //     function stim1_first() {
    //       AddStimulus(1100, 250, 50, 0.25, 9999);
    //     }

    //     function stim1() {
    //       AddStimulus(1100, 250, 50, 0.25, 1);
    //     }

    //     function stim2() {
    //       AddStimulus(2300, 250, 50, 0.25, 1);
    //     }

    //     function stim3() {
    //       AddStimulus(1100, 250, 25, 0.25, 1);
    //     }

    //     function stim4() {
    //       AddStimulus(2300, 250, 25, 0.25, 1);
    //     }

    //     function stim4_last() {
    //       console.log('last');
    //       AddStimulus(2300, 250, 25, 0.25, 9998);
    //     }

    //     function end_stim() {
    //       AddStimulus(0, 0, 0, 0, 0);
    //       setstate(2);
    //       setstateSetting(2);
    //       setstim("자극 없음")
    //     }

    //     let iterationCount = 0;
    //     const maxIterations = 5;

    //     function runIteration() {
    //       if (iterationCount == 0) stim1_first();
    //       else stim1();

    //       let t1 = setTimeout(stim2, 15000);
    //       settimeout1(t1);
    //       let t2 = setTimeout(stim3, 30000);
    //       settimeout2(t2)
    //       let t3 = null
    //       if (iterationCount == maxIterations) t3 = setTimeout(stim4_last, 45000);
    //       else t3 = setTimeout(stim4, 45000);
    //       settimeout3(t3)
    //       iterationCount++;

    //       if (iterationCount > 0) {
    //         if (iterationCount == maxIterations) {
    //           clearInterval(intervalID); // 지정된 횟수만큼 반복 후 interval 중지
    //           setTimeout(end_stim, 60000);
    //         }
    //       }
    //     }

    //     runIteration();

    //     const intervalID = setInterval(runIteration, 60000);
    //     setglobalInterval(intervalID);
    //   }
    //   setTimeout(() => {
    //     stim_2();
    //   }, 300000)
    // }
    else if (user_id === "ntx_sample_new") {
      function stim1_first() {
        AddStimulus(1100, 250, 50, 0.25, 9999);
      }

      function stim1() {
        AddStimulus(1100, 250, 50, 0.25, 1);
      }

      function stim2() {
        AddStimulus(2300, 250, 50, 0.25, 1);
      }

      function stim3() {
        AddStimulus(1100, 250, 25, 0.25, 1);
      }

      function stim4() {
        AddStimulus(2300, 250, 25, 0.25, 1);
      }

      function stim4_last() {
        console.log('last');
        AddStimulus(2300, 250, 25, 0.25, 9998);
      }

      function end_stim() {
        AddStimulus(0, 0, 0, 0, 0);
        setstate(2);
        setstateSetting(2);
        setstim("자극 없음")
      }

      let iterationCount = 0;
      const maxIterations = 5;

      function runIteration() {
        if (iterationCount == 0) stim1_first();
        else stim1();

        let t1 = setTimeout(stim2, 15000);
        settimeout1(t1);
        let t2 = setTimeout(stim3, 30000);
        settimeout2(t2)
        let t3 = null
        if (iterationCount == maxIterations) t3 = setTimeout(stim4_last, 45000);
        else t3 = setTimeout(stim4, 45000);
        settimeout3(t3)
        iterationCount++;

        if (iterationCount > 0) {
          if (iterationCount == maxIterations) {
            clearInterval(intervalID); // 지정된 횟수만큼 반복 후 interval 중지
            setTimeout(end_stim, 60000);
          }
        }
      }

      runIteration();

      const intervalID = setInterval(runIteration, 60000);
      setglobalInterval(intervalID);
    }
    // else if (user_id === 'hskim'){
    //   handleup10();
    //   setTimeout(() => {
    //     vip_basic();
    //   }, 900000);
    // }
    else if (user_id === "ntx_trial" || user_id === "jsi" || user_id === "ntx_vip_new" || user_id === "lyb" || user_id === "ntx_vip4" || user_id === 'ldu' || user_id === 'key' || user_id === 'khg') {
      vip_basic();
    }
    else if (user_id === "ntx_vip") {
      AddStimulus(2300, 250, 50, 10, 15);
    }
    // else if (user_id === "ntx_vip2" || user_id === "jjs" || user_id === 'khs' || user_id === 'cyc' || user_id === 'hskim' || user_id === 'ltj' || user_id === 'kny') {
    //   function stim_1_1(){
    //     function stim1_first() {
    //       AddStimulus(1100, 400, 100, 0.5, 9999);
    //     }
    
    //     function stim1() {
    //       AddStimulus(1100, 400, 100, 0.5, 1);
    //     }
    
    //     let iterationCount = 0;
    //     const maxIterations = 8;
    
    //     function runIteration() {
    //       if (iterationCount === 0){
    //         stim1_first();
    //       }
    //       else{
    //         stim1();
    //       }
          
    //       iterationCount++;
    
    //       if (iterationCount >= maxIterations){
    //         clearInterval(intervalID);
    //       }
    
    //     }
    
    //     runIteration();
    
    //     const intervalID = setInterval(runIteration, 60000);
    //     setglobalInterval(intervalID);
    //   } 

    //   function stim_1_2(){
    //     function stim1() {
    //       AddStimulus(2300, 250, 100, 0.5, 1);
    //     }

    //     function end_stim() {
    //       AddStimulus(0, 0, 0, 0, 0);
    //       setstate(2);
    //       setstateSetting(2);
    //       setstim("자극 없음")
    //     }


    //     let iterationCount = 0;
    //     const maxIterations = 7;

    //     function runIteration() {
    //       stim1();

    //       iterationCount++;

    //       if (iterationCount >= maxIterations){
    //         clearInterval(intervalID);
    //         setTimeout(end_stim, 30000);
    //       }

    //     }

    //     runIteration();

    //     const intervalID = setInterval(runIteration, 60000);
    //     setglobalInterval(intervalID);

    //   }

    //   function stim_1(){
    //     stim_1_1();
    //     setTimeout(() => {
    //       stim_1_2();
    //     }, 480000);
    //   }

    //   function stim_2_1(){
    //     function stim1(){
    //       AddStimulus(2300, 100, 33, 0.167, 1);
    //     }
    
    //     let iterationCount = 0;
    //     const maxIterations = 12;
    
    //     function runIteration() {
    //       stim1();
    
    //       iterationCount ++;
    
    //       if (iterationCount >= maxIterations){
    //         clearInterval(intervalID);
    //       }
    
    //     }
    
    //     runIteration();
    //     const intervalID = setInterval(runIteration, 20000);
    //     setglobalInterval(intervalID);
    
    //   }
    
    //   function stim_2_2(){
    //     function stim1() {
    //       AddStimulus(2300, 100, 25, 0.167, 1);
    //     }
    
    //     function stim1_last() {
    //       AddStimulus(2300, 100, 25, 0.167, 9998);
    //     }
    
    //     function end_stim() {
    //       AddStimulus(0, 0, 0, 0, 0);
    //       setstate(2);
    //       setstateSetting(2);
    //       setstim("자극 없음")
    //     }
    
    //     let iterationCount = 0;
    //     const maxIterations = 18;
    
    //     function runIteration() {
    //       if (iterationCount == maxIterations - 1){
    //         stim1_last();
    //       }
    //       else {
    //         stim1();
    //       }
    
    //       iterationCount++;
    
    //       if (iterationCount >= maxIterations){
    //         clearInterval(intervalID);
    //         setTimeout(end_stim, 10000);
    //       }
    
    //     }
      
    //     runIteration();
    
    //     const intervalID = setInterval(runIteration, 20000);
    //     setglobalInterval(intervalID);
    //   }
    
    //   function stim_2(){
    //     stim_2_1();
    //     setTimeout(() => {
    //       stim_2_2();
    //     }, 240000);
    //   }

    //   stim_1();
    //   setTimeout(() => {
    //     stim_2();
    //   }, 1200000)

    // }
    else if (user_id === "ntx_vip2" || user_id === "jjs" || user_id === 'khs' || user_id === 'cyc' || user_id === 'hskim' || user_id === 'ltj' || user_id === 'kny') {
        stim_10hz_mixed();
        setTimeout(() => {
          stim_30hz_40hz_mixed();
        }, 600000 * 2)
    }
    else if (user_id === "ntx_ec_lab") {
      AddStimulus(950, 250, 50, 10, 15);
      // setTimeout(() => {
      //   AddStimulus(5, 250, 50, 10, 9988);
      // }, 600000*2);
      setTimeout(() => {
        handleup4_new();
      }, 600000 * 2)
    }
    else if (user_id === "ntx_ec_lab2") {
      AddStimulus(0, 0, 0, 10, 15);
      setTimeout(() => {
        AddStimulus(0, 0, 0, 10, 9988);
      }, 600000 * 2);
    }
    else if (notsham.test(user_id)) {
      AddStimulus(950, 250, 50, 10, 15);
      setTimeout(() => {
        handleup4_new();
      }, 600000 * 2)
    }
    else if (sham.test(user_id)) {
      AddStimulus(0, 0, 0, 10, 15);
      setTimeout(() => {
        AddStimulus(0, 0, 0, 10, 9988);
      }, 600000 * 2);
    }
    else {
      AddStimulus(800, 250, 50, 20, 15);
    }
    setstate(1);
    setstateSetting(1);
  };

  const handleup2 = () => {
    AddStimulus(0, 0, 0, 0, 0);
    setstate(2);
    setstateSetting(2);
  };

  const handleup4 = () => {
    AddStimulus(0, 0, 0, 0, 7777);
    setstate(2);
    setstateSetting(2);
  };

  const handleup5 = () => {
    AddStimulus(1100, 50, 50, 10, 15); // amplitude 수정
  }

  const handleup6 = () => {
    AddStimulus(500, 500, 50, 10, 15); // amplitude 수정
  }

  const handleup7 = () => {
    function stim1_first() {
      AddStimulus(1100, 50, 50, 0.5, 9999); // amplitude 수정
    }

    function stim1() {
      AddStimulus(1100, 50, 50, 0.5, 1); // amplitude 수정
    }

    function stim2() {
      AddStimulus(500, 500, 50, 0.5, 1); // amplitude 수정
    }

    function stim2_last() {
      AddStimulus(500, 500, 50, 0.5, 9998); // amplitude 수정
    }

    function end_stim() {
      AddStimulus(0, 0, 0, 0, 0);
      setstate(2);
      setstateSetting(2);
      setstim("자극 없음")
    }

    let iterationCount = 0;
    const maxIterations = 10;

    function runIteration() {
      if (iterationCount == 0) stim1_first();
      else stim1();

      let t1 = null;
      if (iterationCount == maxIterations) t1 = setTimeout(stim2_last, 30000);
      else t1 = setTimeout(stim2, 30000);
      settimeout1(t1);
      iterationCount++;

      if (iterationCount > 0) {
        if (iterationCount == maxIterations) {
          clearInterval(intervalID); // 지정된 횟수만큼 반복 후 interval 중지
          setTimeout(end_stim, 60000);
        }
      }
    }

    runIteration();

    const intervalID = setInterval(runIteration, 60000);
    setglobalInterval(intervalID);

  }

  const handleup3 = () => {
    // AddStimulus(4095, 50, 200, 200, 15);
    // setstate(3);
    // setstateSetting(3);
    function stim1_first() {
      AddStimulus(2300, 250, 50, 0.25, 9999);
    }

    function stim1() {
      AddStimulus(2300, 250, 50, 0.25, 1);
    }

    function stim2() {
      AddStimulus(2700, 250, 50, 0.25, 1);
    }

    function stim3() {
      AddStimulus(2300, 250, 25, 0.25, 1);
    }

    function stim4() {
      AddStimulus(2700, 250, 25, 0.25, 1);
    }

    function stim4_last() {
      console.log('last');
      AddStimulus(2700, 250, 25, 0.25, 9998);
    }

    function end_stim() {
      AddStimulus(0, 0, 0, 0, 0);
      setstate(2);
      setstateSetting(2);
      setstim("자극 없음")
    }

    let iterationCount = 0;
    const maxIterations = 10;

    function runIteration() {
      if (iterationCount == 0) stim1_first();
      else stim1();

      let t1 = setTimeout(stim2, 15000);
      settimeout1(t1);
      let t2 = setTimeout(stim3, 30000);
      settimeout2(t2)
      let t3 = null
      if (iterationCount == maxIterations) t3 = setTimeout(stim4_last, 45000);
      else t3 = setTimeout(stim4, 45000);
      settimeout3(t3)
      iterationCount++;

      if (iterationCount > 0) {
        if (iterationCount == maxIterations) {
          clearInterval(intervalID); // 지정된 횟수만큼 반복 후 interval 중지
          setTimeout(end_stim, 60000);
        }
      }
    }

    runIteration();

    const intervalID = setInterval(runIteration, 60000);
    setglobalInterval(intervalID);
  };

  const handleup_new = () => {
    AddStimulus(1100, 250, 50, 10, 15);
  }

  const handleup4_new = () => {
    function stim1_first() {
      AddStimulus(250, 250, 50, 0.25, 1);
    }

    function stim1() {
      AddStimulus(250, 250, 50, 0.25, 1);
    }

    function stim2() {
      AddStimulus(950, 250, 50, 0.25, 1);
    }

    function stim3() {
      AddStimulus(250, 250, 25, 0.25, 1);
    }

    function stim4() {
      AddStimulus(950, 250, 25, 0.25, 1);
    }

    function stim4_last() {
      console.log('last');
      AddStimulus(950, 250, 25, 0.25, 9998);
    }

    function end_stim() {
      AddStimulus(0, 0, 0, 0, 0);
      setstate(2);
      setstateSetting(2);
      setstim("자극 없음")
    }

    let iterationCount = 0;
    const maxIterations = 10;

    function runIteration() {
      if (iterationCount == 0) stim1_first();
      else stim1();

      let t1 = setTimeout(stim2, 15000);
      settimeout1(t1);
      let t2 = setTimeout(stim3, 30000);
      settimeout2(t2)
      let t3 = null
      if (iterationCount == maxIterations) t3 = setTimeout(stim4_last, 45000);
      else t3 = setTimeout(stim4, 45000);
      settimeout3(t3)
      iterationCount++;

      if (iterationCount > 0) {
        if (iterationCount == maxIterations) {
          clearInterval(intervalID); // 지정된 횟수만큼 반복 후 interval 중지
          setTimeout(end_stim, 60000);
        }
      }
    }

    runIteration();

    const intervalID = setInterval(runIteration, 60000);
    setglobalInterval(intervalID);
  };

  const handleup3_new = () => {
    // AddStimulus(4095, 50, 200, 200, 15);
    // setstate(3);
    // setstateSetting(3);
    function stim1_first() {
      AddStimulus(1100, 250, 50, 0.25, 1);
    }

    function stim1() {
      AddStimulus(1100, 250, 50, 0.25, 1);
    }

    function stim2() {
      AddStimulus(2300, 250, 50, 0.25, 1);
    }

    function stim3() {
      AddStimulus(1100, 250, 25, 0.25, 1);
    }

    function stim4() {
      AddStimulus(2300, 250, 25, 0.25, 1);
    }

    function stim4_last() {
      console.log('last');
      AddStimulus(750, 250, 25, 0.25, 9998);
    }

    function end_stim() {
      AddStimulus(0, 0, 0, 0, 0);
      setstate(2);
      setstateSetting(2);
      setstim("자극 없음")
    }

    let iterationCount = 0;
    const maxIterations = 10;

    function runIteration() {
      if (iterationCount == 0) stim1_first();
      else stim1();

      let t1 = setTimeout(stim2, 15000);
      settimeout1(t1);
      let t2 = setTimeout(stim3, 30000);
      settimeout2(t2)
      let t3 = null
      if (iterationCount == maxIterations) t3 = setTimeout(stim4_last, 45000);
      else t3 = setTimeout(stim4, 45000);
      settimeout3(t3)
      iterationCount++;

      if (iterationCount > 0) {
        if (iterationCount == maxIterations) {
          clearInterval(intervalID); // 지정된 횟수만큼 반복 후 interval 중지
          setTimeout(end_stim, 60000);
        }
      }
    }

    runIteration();

    const intervalID = setInterval(runIteration, 60000);
    setglobalInterval(intervalID);
  };

  const handleup10 = () => {
    AddStimulus(2300, 250, 25, 10, 15); // amplitude 수정
  }

  const vip_basic = () => {
    function stim1_first() {
      AddStimulus(2300, 250, 50, 0.25, 9999);
    }

    function stim1() {
      AddStimulus(2300, 250, 50, 0.25, 1);
    }

    function stim2() {
      AddStimulus(2700, 250, 50, 0.25, 1);
    }

    function stim3() {
      AddStimulus(2300, 250, 25, 0.25, 1);
    }

    function stim4() {
      AddStimulus(2700, 250, 25, 0.25, 1);
    }

    function stim4_last() {
      console.log('last');
      AddStimulus(2700, 250, 25, 0.25, 9998);
    }

    function end_stim() {
      AddStimulus(0, 0, 0, 0, 0);
      setstate(2);
      setstateSetting(2);
      setstim("자극 없음")
    }

    let iterationCount = 0;
    const maxIterations = 10;

    function runIteration() {
      if (iterationCount == 0) stim1_first();
      else stim1();

      let t1 = setTimeout(stim2, 15000);
      settimeout1(t1);
      let t2 = setTimeout(stim3, 30000);
      settimeout2(t2)
      let t3 = null
      if (iterationCount == maxIterations) t3 = setTimeout(stim4_last, 45000);
      else t3 = setTimeout(stim4, 45000);
      settimeout3(t3)
      iterationCount++;

      if (iterationCount > 0) {
        if (iterationCount == maxIterations) {
          clearInterval(intervalID); // 지정된 횟수만큼 반복 후 interval 중지
          setTimeout(end_stim, 60000);
        }
      }
    }

    runIteration();

    const intervalID = setInterval(runIteration, 60000);
    setglobalInterval(intervalID);
  }

  const stim_3hz_30hz_5min = () => {
    AddStimulus(2300, 250, 333, 5, 15);
    setTimeout(() => {
      AddStimulus(2300, 250, 33, 5, 15);
    }, 300000)
  }

  const stim_3hz_20hz_40hz = () => {    
    function convertHzToMs(hz) {
        return Math.round(1000 / hz);
    }
    
    function stim3Hz_first() {
      const freqMs = convertHzToMs(3);
      AddStimulus(2300, 250, freqMs, 0.25, 9999);
  }

    function stim3Hz() {
        const freqMs = convertHzToMs(3);
        AddStimulus(2300, 250, freqMs, 0.25, 1);
    }
    
    function stim20HzFirst() {
        const freqMs = convertHzToMs(20);
        AddStimulus(2700, 250, freqMs, 0.25, 1);
    }
    
    function stim40Hz() {
        const freqMs = convertHzToMs(40);
        AddStimulus(2300, 250, freqMs, 0.5, 1);
    }
    
    function stim20HzLast() {
        const freqMs = convertHzToMs(20);
        AddStimulus(2700, 250, freqMs, 0.25, 9998);
    }
    
    function end_stim() {
      AddStimulus(0, 0, 0, 0, 0);
      setstate(2);
      setstateSetting(2);
      setstim("자극 없음")
    }

        
    let iterationCount = 0;
    const maxIterations = 3; // Adjust the iterations to 3 to fit within 10 minutes

    function runIteration() {
      if (iterationCount == 0) stim3Hz_first();
      else stim3Hz();

      let t1 = setTimeout(stim20HzFirst, 30000);
      settimeout1(t1);
      let t2 = setTimeout(stim40Hz, 60000);
      settimeout2(t2);
      let t3=null;
      if (iterationCount == maxIterations) t3 = setTimeout(stim20HzLast, 120000);
      else t3 = setTimeout(stim20HzFirst, 120000);
      settimeout3(t3)
      iterationCount++;

      if (iterationCount > 0) {
        if (iterationCount == maxIterations) {
          clearInterval(intervalID); // 지정된 횟수만큼 반복 후 interval 중지
          setTimeout(end_stim, 120000);
        }
      }
    }

    runIteration();

    const intervalID = setInterval(runIteration, 180000); // Set interval to 3 minutes
    setglobalInterval(intervalID);
  }

  const stim_10hz_1ma_400us = () => {
    function stim1_first() {
      AddStimulus(1100, 400, 200, 0.5, 9999);
    }

    function stim1() {
      AddStimulus(1100, 400, 200, 0.5, 1);
    }

    let iterationCount = 0;
    const maxIterations = 8;

    function runIteration() {
      if (iterationCount === 0){
        stim1_first();
      }
      else{
        stim1();
      }
      
      iterationCount++;

      if (iterationCount >= maxIterations){
        clearInterval(intervalID);
      }

    }

    runIteration();

    const intervalID = setInterval(runIteration, 45000);
    setglobalInterval(intervalID);
  } 

  const stim_10hz_3ma_250us = () => {
    function stim1_first() {
      AddStimulus(2300, 250, 100, 0.5, 1);
    }

    function stim1_last(){
      AddStimulus(2300, 250, 100, 0.5, 9998);
    }

    function end_stim() {
      AddStimulus(0, 0, 0, 0, 0);
      setstate(2);
      setstateSetting(2);
      setstim("자극 없음")
    }


    let iterationCount = 0;
    const maxIterations = 7;

    function runIteration() {
      if (iterationCount == maxIterations -1){
        stim1_last();
      } else {
        stim1_first();
      }

      iterationCount++;

      if (iterationCount >= maxIterations){
        clearInterval(intervalID);
        setTimeout(end_stim, 30000);
      }

    }

    runIteration();

    const intervalID = setInterval(runIteration, 45000);
    setglobalInterval(intervalID);

  }

  const stim_10hz_mixed = () => {
    stim_10hz_1ma_400us();
    setTimeout(() => {
      stim_10hz_3ma_250us();
    }, 480000);
  }

  const stim_30hz_3ma_100us = () => {
    function stim1_first(){
      AddStimulus(2300, 100, 33, 0.167, 9999);
    }

    function stim1(){
      AddStimulus(2300, 100, 33, 0.167, 1);
    }

    let iterationCount = 0;
    const maxIterations = 21;

    function runIteration() {
      if (iterationCount === 0){
        stim1_first();
      }
      else {
        stim1();
      }

      iterationCount ++;

      if (iterationCount >= maxIterations){
        clearInterval(intervalID);
      }

    }

    runIteration();
    const intervalID = setInterval(runIteration, 20000);
    setglobalInterval(intervalID);

  }

  const stim_40hz_3ma_100us = () => {
    function stim1() {
      AddStimulus(2300, 100, 25, 0.167, 1);
    }

    function stim1_last() {
      AddStimulus(2300, 100, 25, 0.167, 9998);
    }

    function end_stim() {
      AddStimulus(0, 0, 0, 0, 0);
      setstate(2);
      setstateSetting(2);
      setstim("자극 없음")
    }

    let iterationCount = 0;
    const maxIterations = 24;

    function runIteration() {
      if (iterationCount == maxIterations - 1){
        stim1_last();
      }
      else {
        stim1();
      }

      iterationCount++;

      if (iterationCount >= maxIterations){
        clearInterval(intervalID);
        setTimeout(end_stim, 10000);
      }

    }
  
    runIteration();

    const intervalID = setInterval(runIteration, 20000);
    setglobalInterval(intervalID);
  }

  const stim_30hz_40hz_mixed = () => {
    stim_30hz_3ma_100us();
    setTimeout(() => {
      stim_40hz_3ma_100us();
    }, 420000);
  }


  const endinterval = () => {
    clearInterval(globalInterval);
    setglobalInterval(null);
    clearTimeout(timeout1);
    clearTimeout(timeout2);
    clearTimeout(timeout3);
    settimeout1(null);
    settimeout2(null);
    settimeout3(null);
  }

  const EndStimulus = (time, limit) => {
    try {

      const delay = time * 60 * 1000;
      const end_stimulus = () => {
        bluetoothService = machine;
        bluetoothService
          .getCharacteristic(WRITE_UUID)
          .then(function (characteristic) {
            var deviceChar = characteristic;
            // const end_ = "100|0";
            var end_ = ""
            if (limit === 7777) {
              end_ = "100|0";
            }
            else {
              end_ = "910|1";
            }
            var uint8array_end = new TextEncoder().encode(end_);
            deviceChar.writeValueWithoutResponse(uint8array_end).then(function () {
              if (limit == 9988 | time == 0)
                alert("자극 종료")
            }
            );
            endinterval();
            setstim("자극 없음");
          });
      }

      setTimeout(() => {
        end_stimulus();
        uploadStimulus(0, 0, 0, 1);
      }, delay);
    }
    catch {
      alert("기기 연결이 중단되었습니다. 페이지를 새로고침 해주세요.");
    }
  }

  React.useEffect(() => {
  }, [valueAmplitude, valueWidth, valueDuration, valueTime, valueLimit, state]);

  function AddStimulus(Amplitude, width, duration, Time, limit) {
    try {
      var sti_intensity = width;
      sti_intensity = parseInt(sti_intensity);
      var sti_interval = duration;
      sti_interval = parseInt(sti_interval);
      var sti_height = Amplitude;
      sti_height = parseInt(sti_height);
      var sti_long = Time;
      sti_long = parseInt(sti_long);

      console.log(sti_intensity + " " + sti_interval + " " + sti_height + " " + sti_long);
      bluetoothService = machine;
      bluetoothService
        .getCharacteristic(WRITE_UUID)
        .then(function (characteristic) {
          var deviceChar = characteristic;
          const cmd_intense = "102|" + sti_intensity;
          var uint8array_intense = new TextEncoder().encode(cmd_intense);
          deviceChar
            .writeValueWithoutResponse(uint8array_intense)
            .then(function () {
              const cmd_interval = "104|" + sti_interval;
              var uint8array_interval = new TextEncoder().encode(cmd_interval);
              deviceChar
                .writeValueWithoutResponse(uint8array_interval)
                .then(function () {
                  const cmd_height = "106|" + sti_height;
                  var uint8array_height = new TextEncoder().encode(cmd_height);
                  deviceChar
                    .writeValueWithoutResponse(uint8array_height)
                    .then(function () {
                      const cmd_intense2 = "910|2";
                      var uint8array_intense2 = new TextEncoder().encode(cmd_intense2);
                      uploadStimulus(Amplitude, width, duration, 0);
                      deviceChar.writeValueWithoutResponse(uint8array_intense2)
                        .then(function () {
                          if (limit === 9988 | limit == 0 | limit == 15 | limit == 7777) {
                            EndStimulus(sti_long, limit);
                          }
                        });
                    });
                });
            });
        });
      setstarttime2(new Date());
      if (Time !== 0) {
        setstim("자극 전달 중");
        if (limit === 15 | limit === 9999) {
          alert("자극 전달 완료");
        }
      }
    }
    catch {
      alert("기기 연결이 중단되었습니다. 페이지를 새로고침 해주세요.");
    }

  }

  function widthAmplitude() {
    if (valueAmplitude == 0) {
      return "0mA";
    } else if (valueAmplitude == 409.5) {
      return "0.1mA";
    } else if (valueAmplitude == 819) {
      return "0.2mA";
    } else if (valueAmplitude == 1228.5) {
      return "0.3mA";
    } else if (valueAmplitude == 1638) {
      return "0.4mA";
    } else if (valueAmplitude == 2047.5) {
      return "0.5mA";
    } else if (valueAmplitude == 2457) {
      return "0.6mA";
    } else if (valueAmplitude == 2866.5) {
      return "0.7mA";
    } else if (valueAmplitude == 3276) {
      return "0.8mA";
    } else if (valueAmplitude == 3685.5) {
      return "0.9mA";
    } else {
      return "1.0mA";;
    }
  }

  function widthTime() {
    if (valueTime == valueDuration) {
      return "Off";
    } else if (valueTime == 500) {
      return "500ms";
    } else if (valueTime == 1000) {
      return "1000ms";
    } else if (valueTime == 1500) {
      return "1500ms";
    } else if (valueTime == 2000) {
      return "2000ms";
    } else {
      return "2500ms";
    }
  }

  function uploadStimulus(amplitude, width, duration, end) {
    var obj = {
      "proto_exp_id": id,
      "intensity": width,
      "interval": duration,
      "height": amplitude,
      "long": 0,
      "time": end,
    }

    const up = async () => {
      const infd = await Api.getPostStimulus(obj, defaultValue);
      console.log(infd);
    };
    up();
  }
  if (user_id !== "ntx_vip") {
    return (
      <Paper
        style={{ height: "100vh", width: "100%", backgroundColor: "#131313" }}
      >
        <Box style={{ color: "#CCCCCC" }}>
          <Box
            style={{
              padding: "0px 10px 30px 10px",
              borderRadius: 5,
              width: "90%",
              marginRight: "5%",
              marginLeft: "5%",
            }}
          >
            <img src={require('./stigit.gif')} alt="loading..." style={{ maxWidth: '100%', height: 'auto', border: 'None' }} />
            <Box sx={{ marginTop: 5 }}>
              <Typography sx={{ fontSize: 25, fontFamily: "GmarketSansMedium" }} color="white" >
                자극 조절 ({stim})
              </Typography>
              <Grid container>
                <Grid item lg={6} md={6} sm={6} xs={1}>
                  <Card sx={{ width: "90%", backgroundColor: "#393939" }} onClick={handleup}>
                    <CardContent>
                      <Typography sx={{ fontSize: 20, fontFamily: "GmarketSansMedium", textAlign: "center" }} color="white" >
                        자극 사용
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={1}>
                  <Card sx={{ width: "90%", backgroundColor: "#393939" }} onClick={handleup2}>
                    <CardContent>
                      <Typography sx={{ fontSize: 20, fontFamily: "GmarketSansMedium", textAlign: "center" }} color="white" >
                        자극 중지
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Box>
      </Paper>
    );
  }
  else {
    return (
      <Paper
        style={{ height: "100vh", width: "100%", backgroundColor: "#131313" }}
      >
        <Box style={{ color: "#CCCCCC" }}>
          <Box
            style={{
              padding: "0px 10px 30px 10px",
              borderRadius: 5,
              width: "90%",
              marginRight: "5%",
              marginLeft: "5%",
            }}
          >
            <img src={require('./stigit.gif')} alt="loading..." style={{ maxWidth: '100%', height: 'auto', border: 'None' }} />
            <Box sx={{ marginTop: 5 }}>
              <Typography sx={{ fontSize: 25, fontFamily: "GmarketSansMedium" }} color="white" >
                자극 조절 ({stim})
              </Typography>
              <Grid container>
                <Grid item lg={3} md={3} sm={3} xs={3}>
                  <Card sx={{ width: "90%", backgroundColor: "#393939" }} onClick={handleup}>
                    <CardContent>
                      <Typography sx={{ fontSize: 20, fontFamily: "GmarketSansMedium", textAlign: "center" }} color="white" >
                        자극1 사용
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item lg={3} md={3} sm={3} xs={3}>
                  <Card sx={{ width: "90%", backgroundColor: "#393939" }} onClick={handleup10}>
                    <CardContent>
                      <Typography sx={{ fontSize: 20, fontFamily: "GmarketSansMedium", textAlign: "center" }} color="white" >
                        자극1-1 사용
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item lg={3} md={3} sm={3} xs={3}>
                  <Card sx={{ width: "90%", backgroundColor: "#393939" }} onClick={handleup3}>
                    <CardContent>
                      <Typography sx={{ fontSize: 20, fontFamily: "GmarketSansMedium", textAlign: "center" }} color="white" >
                        자극2 사용
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item lg={3} md={3} sm={3} xs={3}>
                  <Card sx={{ width: "90%", backgroundColor: "#393939" }} onClick={handleup_new}>
                    <CardContent>
                      <Typography sx={{ fontSize: 20, fontFamily: "GmarketSansMedium", textAlign: "center" }} color="white" >
                        자극3 사용
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                
              </Grid>
              <br></br>
              <Grid container>
                <Grid item lg={3} md={3} sm={3} xs={3}>
                  <Card sx={{ width: "90%", backgroundColor: "#393939" }} onClick={handleup3_new}>
                    <CardContent>
                      <Typography sx={{ fontSize: 20, fontFamily: "GmarketSansMedium", textAlign: "center" }} color="white" >
                        자극4 사용
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item lg={3} md={3} sm={3} xs={3}>
                  <Card sx={{ width: "90%", backgroundColor: "#393939" }} onClick={handleup5}>
                    <CardContent>
                      <Typography sx={{ fontSize: 20, fontFamily: "GmarketSansMedium", textAlign: "center" }} color="white" >
                        자극 M1 사용
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item lg={3} md={3} sm={3} xs={3}>
                  <Card sx={{ width: "90%", backgroundColor: "#393939" }} onClick={handleup6}>
                    <CardContent>
                      <Typography sx={{ fontSize: 20, fontFamily: "GmarketSansMedium", textAlign: "center" }} color="white" >
                        자극 M2 사용
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item lg={3} md={3} sm={3} xs={3}>
                  <Card sx={{ width: "90%", backgroundColor: "#393939" }} onClick={handleup7}>
                    <CardContent>
                      <Typography sx={{ fontSize: 20, fontFamily: "GmarketSansMedium", textAlign: "center" }} color="white" >
                        자극 M3 사용
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
              <br></br>
              <Grid container>
                <Grid item lg={3} md={3} sm={3} xs={1}>
                  <Card sx={{ width: "90%", backgroundColor: "#393939" }} onClick={stim_3hz_30hz_5min}>
                    <CardContent>
                      <Typography sx={{ fontSize: 20, fontFamily: "GmarketSansMedium", textAlign: "center" }} color="white" >
                        자극 5 사용
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item lg={3} md={3} sm={3} xs={1}>
                  <Card sx={{ width: "90%", backgroundColor: "#393939" }} onClick={stim_3hz_20hz_40hz}>
                    <CardContent>
                      <Typography sx={{ fontSize: 20, fontFamily: "GmarketSansMedium", textAlign: "center" }} color="white" >
                        자극 6 사용
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item lg={3} md={3} sm={3} xs={1}>
                  <Card sx={{ width: "90%", backgroundColor: "#393939" }} onClick={stim_10hz_mixed}>
                    <CardContent>
                      <Typography sx={{ fontSize: 20, fontFamily: "GmarketSansMedium", textAlign: "center" }} color="white" >
                        자극 7 사용
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item lg={3} md={3} sm={3} xs={1}>
                  <Card sx={{ width: "90%", backgroundColor: "#393939" }} onClick={stim_30hz_40hz_mixed}>
                    <CardContent>
                      <Typography sx={{ fontSize: 20, fontFamily: "GmarketSansMedium", textAlign: "center" }} color="white" >
                        자극 8 사용
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
              <br></br>
              <Grid container>
                <Grid item lg={6} md={6} sm={6} xs={1}>
                  <Card sx={{ width: "90%", backgroundColor: "#393939" }} onClick={handleup2}>
                    <CardContent>
                      <Typography sx={{ fontSize: 20, fontFamily: "GmarketSansMedium", textAlign: "center" }} color="white" >
                        자극 중지
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={1}>
                  <Card sx={{ width: "90%", backgroundColor: "#393939" }} onClick={handleup4}>
                    <CardContent>
                      <Typography sx={{ fontSize: 20, fontFamily: "GmarketSansMedium", textAlign: "center" }} color="white" >
                        자극 완전 중지
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Box>
      </Paper>
    );
  }
}

export default ExperimentMachineListPageMiddle;
